import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/navLogo.png';
import userLogo from '../../images/user.png';
import './navbar-protected.css';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanup } from '../../store/upload-info';
import { I18n } from 'aws-amplify';


const NavbarUser = () => {
    const username = useSelector(state => state.userInfo.username)

    return (
        <>
            <div id="nameDiv" className="" style={{ paddingLeft: '1rem' }}>
                <span>{username}</span>
                <br />
                {/* <Link style={{ textDecoration: 'none' }} to="/profile">
                    <span>go to profile</span>
                </Link> */}
            </div>
        </>
    )
}

const NavbarDropDown = () => {
    const dispatch = useDispatch()
    return (
        <>
            <div className="navbar-dropdown">
                {/* <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app">
                    <div className="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-center is-align-content-center is-align-items-center" >
                        <i className="fa fa-folder has-background-grey-lighter" style={{ marginRight: '1rem', borderRadius: 30, padding: '0.4rem' }}></i>
                        <span>PDF Folders</span>
                    </div>
                </Link> */}
                <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app/task-history">
                    <div className="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-center is-align-content-center is-align-items-center" >
                        <i className="fa fa-history has-background-grey-lighter" style={{ marginRight: '1rem', borderRadius: 30, padding: '0.4rem' }}></i>
                        <span>
                            {I18n.get("Task History")}
                            </span>
                    </div>

                </Link>
                <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app/pricing" onClick={() => {
                    // window.location.href = ''

                    
                    // dispatch(cleanup())
                }}>
                    <div className="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-center is-align-content-center is-align-items-center" >
                        <i className="fa fa-book has-background-grey-lighter" style={{ marginRight: '1rem', borderRadius: 30, padding: '0.4rem' }}></i>
                        <span>{I18n.get('Plan Options')}</span>
                    </div>

                </Link>
                {/* <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app">
                    <div className="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-center is-align-content-center is-align-items-center" >
                        <i className="fa fa-pen has-background-grey-lighter" style={{ marginRight: '1rem', borderRadius: 30, padding: '0.4rem' }}></i>
                        <span>Update Plan</span>
                    </div>

                </Link> */}
                {/* <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app">
                    <div className="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-center is-align-content-center is-align-items-center" >
                        <i className="fa fa-file-invoice has-background-grey-lighter" style={{ marginRight: '1rem', borderRadius: 30, padding: '0.4rem' }}></i>
                        <span>Billing and Usage</span>
                    </div>
                </Link> */}
                <div className="navbar-item">
                    <AmplifySignOut></AmplifySignOut>
                </div>
            </div>
        </>
    )
}

// const onClick

const Navbar = () => {
    const [dropDownIsActive, isSetDropDownIsActive] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        (function () {
            var burger = document.querySelector('.navbar-burger');
            var menu = document.querySelector('#' + burger.dataset.target);
            burger.addEventListener('click', function () {
                burger.classList.toggle('is-active');
                menu.classList.toggle('is-active');
            });
        })();

    }, [])


    const onMouseOutHandler = e => {
        isSetDropDownIsActive(false)
    }

    const onMouseOverHandler = e => {
        isSetDropDownIsActive(true)
    }

    return (
        <>
            {/* <nav className="navbar" style={{ boxShadow: '1px 0px 9px 1px #DCDCDC' }}> */}
            <nav className="navbar">
                <div className="container">
                    <div className="navbar-brand">
                        <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/app" onClick={() => {
                            window.location.href = ''
                            const anchor = document.querySelector('#red-wrapper-section')
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            dispatch(cleanup())
                        }}>
                            <img
                                src={logo}
                                className="navbar-logo-protected"
                            />
                        </Link>
                        <span className="navbar-burger burger" data-target="navbarMenuHeroB">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>
                    <div id="navbarMenuHeroB" className="navbar-menu">
                        <div className="navbar-end">
                            <div className="" >
                                <div className="navbar-item">
                                    <div className="navbar-item  is-hoverable">
                                        <div id="arrowless-link">
                                            <div className="navbar-link is-arrowless" onMouseLeave={onMouseOutHandler} onMouseOver={onMouseOverHandler}>
                                                <div id="hover-element" className="is-flex is-flex-direction-row is-flex-wrap-wrap-reverse is-justify-content-center is-align-content-center is-align-items-center">
                                                    <i className="fas fa-user-circle fa-3x"></i>
                                                    {
                                                        dropDownIsActive === true
                                                            ? <NavbarUser></NavbarUser>
                                                            : <></>
                                                    }
                                                </div>
                                                {
                                                    dropDownIsActive === true
                                                        ? <NavbarDropDown />
                                                        : <></>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;

//is-flex is-flex-direction-column is-flex-wrap-nowrap is-justify-content-flex-start is-align-content-flex-start is-align-items-flex-start

//is-flex is-justify-content-flex-start is-align-content-flex-start is-align-items-flex-start