import React, { useEffect, useState } from 'react';
import { getTaskHistory } from '../../graphql/amplify-api';
import moment from 'moment';
import * as _ from "lodash";
import byteSize from 'byte-size';
import Loading from '../loader/loading';
import './task-history-table.css';
import loaderImg from '../../images/loading.gif'
import { Storage } from 'aws-amplify';
import { DownloadBucketConfig } from '../../aws-exports';
import { I18n } from 'aws-amplify';

Storage.configure(DownloadBucketConfig)

const TaskHistoryTable = (props) => {
    const [taskHistory, setTaskHistory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [nextToken, setnextToken] = useState(false)
    const [prevToken, setPrevToken] = useState(false)
    const [loading, setLoadingMessage] = useState('')
    const [defaultFrom, setDefautFrom] = useState(new Date(moment().format('YYYY-MM-01T00:00:00')).getTime())
    const [defaultTo, setDefautTo] = useState(new Date(moment().format('YYYY-MM-DDT23:59:59')).getTime())
    const [pageTokens, pushPageToken] = useState([false])
    const [pageCounter, setPageConuter] = useState(1)

    const fetchTaskHistory = async (from, to, limit, nextToken = false) => {
        const params = [from, to, limit, nextToken].filter(f => f !== false)
        const data = await getTaskHistory(...params)
        return data
    }

    const fetchNextPage = async (from, to, limit, nextPageToken) => {
        setIsLoading(true)
        setLoadingMessage('is-loading')
        const data = await fetchTaskHistory(from, to, limit, nextPageToken)
        const { tasks, nextToken = false } = data
        setTaskHistory(tasks)
        setPageConuter(pageCounter + 1)
        if (nextToken) {
            pushPageToken([...pageTokens, nextToken])
            setnextToken(nextToken)
        } else {
            setnextToken(false)
        }
        setIsLoading(false)
        setLoadingMessage('')
    }

    const fetchPrevPage = async (from, to, limit, prevToken) => {
        setIsLoading(true)
        setLoadingMessage('is-loading')
        const data = await fetchTaskHistory(from, to, limit, prevToken)
        const { tasks } = data
        setTaskHistory(tasks)
        setIsLoading(false)
        setPageConuter(pageCounter - 1)
        setnextToken(pageTokens[pageCounter - 1])
        setLoadingMessage('')
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            setLoadingMessage('is-loading')
            const data = await fetchTaskHistory(defaultFrom, defaultTo, 10)
            const { tasks, nextToken = false } = data

            setTaskHistory(tasks || [])
            if (nextToken) {
                setnextToken(nextToken)
                pushPageToken([...pageTokens, nextToken])
            }
            setIsLoading(false)
            setLoadingMessage('')
            // setErrorMessage('')
        })()

        document.getElementById('from-date-picker-table').value = moment.unix(defaultFrom / 1000).format('YYYY-MM-DD')
        document.getElementById('to-date-picker-table').value = moment.unix(defaultTo / 1000).format('YYYY-MM-DD')


    }, [])

    return (
        <div>
            <section className="hero" style={{ minHeight: '60vh' }}>
                <div className="hero-body">
                    <div className="container">
                        <div className="columns" style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                            <div className="column is-half has-text-left">
                                <h1 className="title">
                                    {I18n.get("Task History")}
                                </h1>

                            </div>
                            <div className="column is-half has-text-right">
                                <div className="" style={{ minWidth: '100%', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                    <button className={`button is-primary is-rounded ${loading}`} onClick={async () => {
                                        setIsLoading(true)

                                        pushPageToken([])
                                        // setPageConuter(1)
                                        setLoadingMessage('is-loading')

                                        const from = document.getElementById('from-date-picker-table').value
                                        const to = document.getElementById('to-date-picker-table').value
                                        const data = await fetchTaskHistory(new Date(`${from}T00:00:00`).getTime(), new Date(`${to}T23:59:59`).getTime(), 10)
                                        const { tasks, nextToken = false } = data

                                        setTaskHistory(tasks || [])
                                        if (nextToken) {
                                            setnextToken(nextToken)
                                            pushPageToken([...pageTokens, nextToken])
                                        }
                                        setIsLoading(false)
                                        setLoadingMessage('')
                                    }}>{I18n.get('Search')}</button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="columns" style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                            <div className="column">
                                <div className="field">
                                    <label className="label">{I18n.get('From Date')}</label>
                                    <div className="control">
                                        <input className="input" type="date" name="from-date-picker-table" id="from-date-picker-table"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">{I18n.get('To Date')}</label>
                                    <div className="control">
                                        <input className="input" type="date" name="to-date-picker-table" id="to-date-picker-table"></input>
                                    </div>
                                </div>

                            </div>
                            <div className="column">

                            </div>
                            <div className="column">


                            </div>
                        </div>
                        <div className="table-container">
                            <table className="table table is-striped table is-hoverable table is-fullwidth has-text-centered">
                                <thead>
                                    <tr>
                                        <th className="has-text-left">{I18n.get('Log ID')}</th>
                                        <th> {I18n.get('Converted File Size')}</th>
                                        <th>{I18n.get('Bill Type')}</th>
                                        <th>{I18n.get('Batch ID')}</th>
                                        <th>{I18n.get('Date & Time')}</th>
                                        <th>{I18n.get("Conversion Status")}</th>
                                        <th>{I18n.get('View All Info')}</th>
                                        <th>{I18n.get('Download File')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        taskHistory.map(task => {
                                            //console.log'task', task)
                                            return (
                                                <tr key={task.logId}>
                                                    <td className="has-text-left">{task.logId}</td>
                                                    <td>{`${byteSize(task.cfMetaData.size)}`}</td>
                                                    <td>{_.startCase(task.rootFolder)}</td>
                                                    <td>{task.batchFolder}</td>
                                                    <td>{moment(parseInt(task.logId)).format('YYYY-MM-DD @ hh:mm A')}</td>
                                                    <td>{task.status === true ? 'Completed' : I18n.get('Failed')}</td>
                                                    <td><button className="button is-info is-light is-fullwidth" onClick={() => {
                                                        const modal = document.getElementById(`modal-${task.logId}`)
                                                        if (modal.classList.contains('is-active') && modal.classList.contains('is-clipped')) {
                                                            modal.classList.remove('is-active')
                                                            modal.classList.remove('is-clipped')
                                                        } else {
                                                            modal.classList.add('is-active')
                                                            modal.classList.add('is-clipped')
                                                        }
                                                    }}>{I18n.get('Info')}</button>
                                                        <div id={`modal-${task.logId}`} className="modal">
                                                            <div className="modal-background"></div>
                                                            <div className="modal-card">
                                                                <header className="modal-card-head">
                                                                    <p className="modal-card-title">{I18n.get('Task Details')}</p>
                                                                    <button className="delete" aria-label="close" onClick={() => {
                                                                        const modal = document.getElementById(`modal-${task.logId}`)
                                                                        modal.classList.remove('is-active')
                                                                        modal.classList.remove('is-clipped')
                                                                    }}></button>
                                                                </header>
                                                                <section className="modal-card-body">
                                                                    <div className="columns">
                                                                        <div className="column is-half has-text-left">
                                                                            <div className="field">
                                                                                <label className="label">{I18n.get('Log ID')}</label>
                                                                                <div className="control">
                                                                                    <p>{task.logId}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="field">
                                                                                <label className="label">{I18n.get('Batch Folder')}</label>
                                                                                <div className="control">
                                                                                    <p> {task.batchFolder}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="field">
                                                                                <label className="label">{I18n.get('Status')}</label>
                                                                                <div className="control">
                                                                                    <p>{task.message}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-half has-text-left" style={{ borderLeft: '1px solid #7070703D' }}>
                                                                            <div className="field">
                                                                                <label className="label">{I18n.get('Converted File Size')}</label>
                                                                                <div className="control">
                                                                                    <p> {`${byteSize(task.cfMetaData.size)}`}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="field">
                                                                                <label className="label">{I18n.get('Date & Time')}</label>
                                                                                <div className="control">
                                                                                    <p> {task.cfMetaData.lastModified ? moment(task.cfMetaData.lastModified).format('YYYY-MM-DD @ hh:mm A') : '-'}</p>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{I18n.get('Files')}</th>
                                                                                <th>{I18n.get('Status')}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {

                                                                                task.files.map((file) => {
                                                                                    if (task.jsonPending.includes(file)) {
                                                                                        return <tr className="has-background-danger-light" key={file}>
                                                                                            <td>{file}</td>
                                                                                            <td className="has-background-danger-light" className="has-text-danger-dark">{I18n.get('Failed')}</td>
                                                                                        </tr>
                                                                                    }
                                                                                    return <tr className="has-background-success-light" key={file}>
                                                                                        <td>{file}</td>
                                                                                        <td className="has-background-success-light" className="has-text-success-dark">{I18n.get('Converted')}</td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>

                                                                    </table>

                                                                </section>
                                                                <footer className="modal-card-foot">

                                                                </footer>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{
                                                        task.status === false
                                                            ? <button id={`${task.logId}-download`} className="button is-danger is-light is-fullwidth" disabled >{I18n.get('Download')}</button>
                                                            : <button id={`${task.logId}-download`} className="button is-success is-light is-fullwidth" onClick={async () => {
                                                                //console.logtask.convertedFilePath)
                                                                const signedUrl = await Storage.get(task.convertedFilePath)
                                                                window.location = signedUrl
                                                            }}>{I18n.get('Download')}</button>
                                                    }



                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="columns is-justify-content-center is-align-content-center is-align-items-center">
                            <div className="column has-text-left">
                            </div>
                            {/* <div className="column has-text-centered">
                                <p>{pageCounter}</p>
                            </div> */}
                            <div className="column has-text-right is-flex-direction-row is-justify-content-center is-align-content-center is-align-items-center is-align-self-center">

                                {
                                    pageCounter > 1
                                        ? <button className={`button is-warning ${loading}`} onClick={async () => {
                                            await fetchPrevPage(new Date(`${document.getElementById('from-date-picker-table').value}T00:00:00`).getTime(), new Date(`${document.getElementById('to-date-picker-table').value}T23:59:59`).getTime(), 10, pageTokens[pageCounter - 2])
                                        }}>{I18n.get('Previous')}</button>
                                        : <button disabled className={`button is-warning ${loading}`}>{I18n.get('Previous')}</button>
                                }
                                &nbsp;  <span className='is-size-5 is-underlined' style={{padding: '1%'}}>{ `${pageCounter}`}</span> &nbsp; 
                                {
                                    nextToken !== false > 1
                                        ? <button className={`button is-success ${loading}`} onClick={async () => {
                                            await fetchNextPage(new Date(`${document.getElementById('from-date-picker-table').value}T00:00:00`).getTime(), new Date(`${document.getElementById('to-date-picker-table').value}T23:59:59`).getTime(), 10, nextToken)
                                        }}>{I18n.get('Next')}</button>
                                        : <button disabled className={`button is-success ${loading}`}>{I18n.get('Next')}</button>
                                }
                            </div>
                        </div>
                        <hr />

                    </div>
                </div>
            </section>
        </div>
    );
}

export default TaskHistoryTable;