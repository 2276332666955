import { I18n } from 'aws-amplify';

export const dict = {
    'pt': {
        //app-home && guest home header text
        'Convert your Energy PDFs into XLSX within a minute': 'Converta suas faturas de energia para dados no excel em segundos',
        'Select the Energy company that you want to convert into XLSX': 'Todas as distribuidoras de energia disponíveis sem custo',
        'Need other fields or different type of pdf?': 'Não encontrou o PDF da sua fatura de energia ou algum campo?',  
        'Contact us': ' Entre em contato com a gente',
        'Browse': 'Selecionar',
        'file selected': 'arquivo selecionado',
        'files selected': 'arquivos selecionados',

        //pricing
        'Pricing Guide': 'Nossos Planos',
        'Pricing': 'Planos',
        'Basic': 'Basic',
        'Free': 'Sem custo',
        '5 PDFs per month': 'Upload de até 5 PDFs por processo',
        'All Energy Companies': 'Processos separados por distribuidora',
        'slow downloads': 'downloads lentos', 
        'Starter': 'Starter',
        '$': 'A partir de  R$',
        '/pdf': '/pdf',
        'pay for what to use': 'Pague pelo que usar',
        'Unlimited PDF uploads': 'Upload ilimitado de PDFs',
        'Simultaneous processing of several distributors': 'Processamento simultâneo de diversas distribuidoras',
        'Processes separated by distributor': 'Processos separados por distribuidora',
        'Monthly payment': 'Pagamento mensal',
        'Contact': 'Comprar',
        
        'Clear All': "Remover todos",
        'Start Process': "Iniciar conversão",
        'Download XLSX': 'Baixar',
        'Start New Process': 'Nova conversão',
        'Failed': 'Erro',
        'Task History': 'Histórico de processos',
        'Successfully uploaded, converting files to XLSX': 'Carregado com sucesso, convertendo arquivos para excel',
        'file': 'arquivo',
        'files': 'arquivos',
        'failed in conversion process': 'com erro na conversão',
        'Oops, all': 'Oops, todos',
        'files failed!': 'arquivos com erro na conversão. Entre em contato com suporte.',
        'Only 5 files can be selected': 'Apenas 5 arquivos podem ser selecionados',
        'Are you an Enterprise?': 'Possui alguma demanda específica?',
        'Contact us': 'Entre em contato que iremos te ajudar',

        //footer
        'QUICK LINKS': 'LINKS RÁPIDOS',
        'Home': 'Home',
        'Pricing Guide': 'Planos',
        'Contact Us/ Report a bug': 'Contato / Reportar erro',
        'FAQs': 'FAQs',

        //navbar-protected
        'Plan Options': 'Opções de plano',

        //task-history
        'Refresh': 'Atualizar',
        'Log ID': 'ID de registro',
        'Converted File Size': 'Tamanho do arquivo convertido',
        'Bill Type': 'Tipo de conta',
        'Batch ID': 'ID de lote',
        'Date & Time': 'Data hora',
        'Conversion Status': 'Status de conversão',
        'Download File': '⇬ Fazer download do arquivo',
        'View All Info': 'Ver todas as informações',
        'Next': 'Próximo',
        'Previous': 'Anterior',
        'Info': 'Informações',
        'Task Details': 'Detalhes da Tarefa',
        'Batch Folder': 'Pasta de lote',
        'Status': 'Status',
        'Files': 'Arquivos',
        'Converted': 'Convertido',
        'Download': 'Download',
        'No data found': 'Nenhum dado encontrado',
        //task-history - Usage & Statistics
        'Usage & Statistics': 'Estatísticas de utilização',
        'From and To fields are required!': 'Os campos De e Para são obrigatórios!',
        'Can not select From greater than To!': 'Não é possível selecionar de maior que até!',
        'Can not select To greater than Today!': 'Não é possível selecionar Para maior que Hoje!',
        'Search': 'Procurar',
        'From Date': 'Da data',
        'To Date': 'Até a presente data',
        'Total Files Uploaded': 'Total de arquivos enviados',
        'Files Successfully Converted': 'Arquivos convertidos com sucesso',
        'Files Failed': 'Arquivos com falha',
        'Average Success Ratio': 'Razão média de sucesso',
        'Average Failure Ratio': 'Razão de falha média',

        //contact us
        'Contact Us': 'Entre em contato conosco',
        'Contact Us from Email': 'Contate-nos por e-mail',
        'Or Contact Us from Whatsapp': 'Ou entre em contato conosco pelo Whatsapp',
        'Name': 'Nome',
        'Email': 'O email',
        'Message': 'Mensagem',
        'Submit': 'Enviar',
        'Reset': 'Redefinir',
        'Name field is required!': 'O campo do nome é obrigatório!',
        'Email field is required!': 'O campo do email é obrigatório!',
        'Can not send blank message!': 'Não é possível enviar mensagem em branco!',
        'Invalid name': 'Nome inválido',
        'Invalid email': 'E-mail inválido',
        'Invalid message!': 'Mensagem inválida!',
        'Name Input': 'Entrada de nome',
        'Email Input': 'Entrada de Email',
        
        //toast messages
        'Upload started, uploading': 'Upload iniciado, upload',
        'We received your message!': 'Recebemos sua mensagem!',
        'You have reached your maximum daily limit, try again tomorrow!': 'Você atingiu seu limite diário máximo, tente novamente amanhã, ou compre créditos!',
        'You only have': 'Você só tem',
        'processes left for today!': 'processos deixados para hoje!',

        //file table file labels
        'Ready to Upload': 'Pronto para fazer upload',
        'Remove': 'Retirar',
        'Uploading': 'Enviando',
        'Converting': 'Convertendo',
        'Uploaded': 'Carregado',

        //FAQs
        'What types of PDF can I convert?': 'Que tipos de PDF posso converter?',
        'You can convert any electronic PDF, which are the templates provided by energy distributors. PDFs scanned or saved as an image will not be processed.': 'Você pode converter qualquer PDF eletrônico, que são os modelos disponibilizados pelas distribuidoras de energia. PDFs escaneados ou salvos como imagem não serão processados.',
        'How do I convert PDFs from several distributors simultaneously?': 'Como converto PDFs de varias distribuidoras simultaneamente?',
        'The platform allows all users to convert their invoices from different distributors individually. To convert several distributors simultaneously it is necessary to contract a plan. Contact us through our service channels to see the best option for your operation.': 'A plataforma permite que todos os usuários convertam as suas faturas de diversas distribuidoras individualmente. Para converter diversas distribuidoras simultaneamente é necessário contratar um plano. Entre em contato pelos nossos canais de atendimento para ver a melhor opção para sua operação.',
        'How do I hire a plan?': 'Como faço para contratar um plano?',
        'To contract a plan, contact us through our service channels and we will serve you.': 'Para contratar um plano entre em contato através dos nossos canais de atendimento que iremos atendê-lo.',
        'My PDF was not processed or it did not extract the data correctly.': 'Meu PDF não foi processado ou não extraiu os dados corretamente.',
        'Sometimes there may be some variation in the PDF format or some field that is not recognized by our reader, in these cases you can report the error through our service channels and we will investigate the failure.': 'Algumas vezes pode haver alguma variação no formato do PDF ou algum campo que não é reconhecido pelo nosso leitor, nesses casos você pode reportar o erro através dos nossos canais de atendimento e iremos averiguar a falha.'

    },
    'en': {
        //app-home && guest home header text
        'Convert your Energy PDFs into XLSX within a minute': 'Convert your Energy PDFs into XLSX within a minute',
        'Select the Energy company that you want to convert into XLSX': 'Select the Energy company that you want to convert into XLSX',
        'Need other fields or different type of pdf? Contact us': 'Need other fields or different type of pdf? Contact us',
        'Browse': 'Browse',
        'file selected': 'file selected',
        'files selected': 'files selected',

        //pricing
        'Pricing Guide': 'Pricing Guide',
        'Pricing': 'Pricing',
        'Basic': 'Basic',
        'Free': 'Free',
        '5 PDFs per month': '5 PDFs per month',
        'All Energy Companies': 'All Energy Companies',
        'slow downloads': 'slow downloads', 
        'Starter': 'Starter',
        '$': '$',
        '/pdf': '/pdf',
        'pay for what to use': 'pay for what to use',
        'Unlimited PDF uploads': 'Unlimited PDF uploads',
        'Simultaneous processing of several distributors': 'Simultaneous processing of several distributors',
        'Processes separated by distributor': 'Processes separated by distributor',
        'Monthly payment': 'Monthly payment',
        'Contact': 'Contact',

        

        'Clear All': "Clear All",
        'Start Process': "Start Process",
        'Download XLSX': 'Download XLSX',
        'Start New Process': 'Start New Process',
        'Failed': 'Failed',
        'Task History': 'Task History',
        'Successfully uploaded, converting files to XLSX': 'Successfully uploaded, converting files to XLSX',
        'file': 'file',
        'files': 'files',
        'failed in conversion process': 'failed in conversion process',
        'Oops, all': 'Oops, all',
        'files failed!': 'files failed!',
        'Only 5 files can be selected': 'Only 5 files can be selected',
        'Are you an Enterprise?': 'Are you an Enterprise?',
        'Contact us': 'Are you an Enterprise?',

        //footer
        'QUICK LINKS': 'QUICK LINKS',
        'Home': 'Home',
        'Pricing Guide': 'Pricing Guide',
        'Contact Us/ Report a bug': 'Contact Us/ Report a bug',
        'FAQs': 'FAQs',

        //navbar-protected
        'Plan Options': 'Plan Options',


        //sign in
        'Sign in to your account': 'Sign in to your account',
        'SIGN IN': 'SIGN IN',

        //task-history
        'Refresh': 'Refresh',
        'Log ID': 'Log ID',
        'Converted File Size': 'Converted File Size',
        'Bill Type': 'Bill Type',
        'Batch ID': 'Batch ID',
        'Date & Time': 'Date & Time',
        'Conversion Status': 'Conversion Status',
        'Download File': 'Download File',
        'View All Info': 'View All Info',
        'Next': 'Next',
        'Previous': 'Previous',
        'Info': 'Info',
        'Task Details': 'Task Details',
        'Batch Folder': 'Batch Folder',
        'Status': 'Status',
        'Files': 'Files',
        'Converted': 'Converted',
        'Download': 'Download',
        'No data found': 'No data found',
        //task-history - Usage & Statistics
        'Usage & Statistics': 'Usage & Statistics',
        'From and To fields are required!': 'From and To fields are required!',
        'Can not select From greater than To!': 'Can not select From greater than To!',
        'Can not select To greater than Today!': 'Can not select To greater than Today!',
        'Search': 'Search',
        'From Date': 'From Date',
        'To Date': 'To Date',
        'Total Files Uploaded': 'Total Files Uploaded',
        'Files Successfully Converted': 'Files Successfully Converted',
        'Files Failed': 'Files Failed',
        'Average Success Ratio': 'Average Success Ratio',
        'Average Failure Ratio': 'Average Failure Ratio',

        //contact us
        'Contact Us': 'Contact Us',
        'Contact Us from Email': 'Contact Us from Email',
        'Or Contact Us from Whatsapp': 'Or Contact Us from Whatsapp',
        'Name': 'Name',
        'Email': 'Email',
        'Message': 'Message',
        'Submit': 'Submit',
        'Reset': 'Reset',
        'Name field is required!': 'Name field is required!',
        'Email field is required!': 'Email field is required!',
        'Can not send blank message!': 'Can not send blank message!',
        'Invalid name': 'Invalid name',
        'Invalid email': 'Invalid email',
        'Invalid message!': 'Invalid message!',
        'Name Input': 'Name Input',
        'Email Input': 'Email Input',
        
        //toast messages
        'Upload started, uploading': 'Upload started, uploading',
        'We received your message!': 'We received your message!',
        'You have reached your maximum daily limit, try again tomorrow!': 'You have reached your maximum daily limit, try again tomorrow!',
        'You only have': 'You only have',
        'processes left for today!': 'processes left for today!',
        
        //table file labels
        'Ready to Upload': 'Ready to Upload',
        'Remove': 'Remove',
        'Uploading': 'Uploading',
        'Converting': 'Converting',
        'Uploaded': 'Uploaded',

        //FAQs
        'What types of PDF can I convert?': 'What types of PDF can I convert?',
        'You can convert any electronic PDF, which are the templates provided by energy distributors. PDFs scanned or saved as an image will not be processed.': 'You can convert any electronic PDF, which are the templates provided by energy distributors. PDFs scanned or saved as an image will not be processed.',
        'How do I convert PDFs from several distributors simultaneously?': 'How do I convert PDFs from several distributors simultaneously?',
        'The platform allows all users to convert their invoices from different distributors individually. To convert several distributors simultaneously it is necessary to contract a plan. Contact us through our service channels to see the best option for your operation.': 'The platform allows all users to convert their invoices from different distributors individually. To convert several distributors simultaneously it is necessary to contract a plan. Contact us through our service channels to see the best option for your operation.',
        'How do I hire a plan?': 'How do I hire a plan?',
        'To contract a plan, contact us through our service channels and we will serve you.': 'To contract a plan, contact us through our service channels and we will serve you.',
        'My PDF was not processed or it did not extract the data correctly.': 'My PDF was not processed or it did not extract the data correctly.',
        'Sometimes there may be some variation in the PDF format or some field that is not recognized by our reader, in these cases you can report the error through our service channels and we will investigate the failure.': 'Sometimes there may be some variation in the PDF format or some field that is not recognized by our reader, in these cases you can report the error through our service channels and we will investigate the failure.'
    },
};

I18n.putVocabularies(dict)
// I18n.setLanguage('en');
