import React, { useEffect, useState } from 'react';
import { getFileCounts } from '../../graphql/amplify-api';
import moment from 'moment';
import * as _ from "lodash";
import loaderImg from '../../images/loading.gif'
import { I18n } from 'aws-amplify';
import Loading from '../loader/loading';

const FileCountsHandler = (props) => {
    const [fileCounts, setFileCounts] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoadingMessage] = useState('')
    const [defaultFrom, setDefautFrom] = useState(new Date(moment().format('YYYY-MM-01T00:00:00')).getTime())
    const [defaultTo, setDefautTo] = useState(new Date(moment().format('YYYY-MM-DDT23:59:59')).getTime())
    

    // const getFileCountsFuncion = async (from = false, to = false) => {
    //     setIsLoading(true)
    //     const param = {}
    //     if (!to) {
    //         param.to = new Date().getTime()
    //     } else {
    //         param.to = to
    //     }

    //     if (!from) {
    //         param.from = to - 2592000000 * 12
    //     } else {
    //         param.from = from
    //     }

    //     const data = await getFileCounts(`${param.from}`, `${param.to}`)
    //     setFileCounts(data)
    //     setIsLoading(false)
    // }

    useEffect(() => {
        (async () => {
            const data = await getFileCounts(defaultFrom, defaultTo)
            console.log('data', data)
            setFileCounts(data)
            setIsLoading(false)
            setLoadingMessage('')
            setErrorMessage('')
        })()

        document.getElementById('from-date-picker').value = moment.unix(defaultFrom/1000).format('YYYY-MM-DD')
        document.getElementById('to-date-picker').value = moment.unix(defaultTo/1000).format('YYYY-MM-DD')


    }, [])

    return (
        <>
            <div>
                <section className="hero" style={{ minHeight: '60vh' }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns" style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                <div className="column is-half has-text-left">
                                    <h1 className="title">
                                        {I18n.get("Usage & Statistics")}
                                    </h1>

                                </div>
                                <div className="column is-half has-text-right">
                                    <div className="" style={{ minWidth: '100%', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                        <button className={`button is-primary is-rounded ${loading}`} onClick={async () => {
                                            setIsLoading(true)
                                            setLoadingMessage('is-loading')
                                            const from = document.getElementById('from-date-picker').value
                                            const to = document.getElementById('to-date-picker').value

                                            //blank dates
                                            if (!from || !to) {
                                                setErrorMessage(I18n.get('From and To fields are required!'))
                                                setIsLoading(false)
                                                setLoadingMessage('')
                                                return
                                            }

                                            //from to size validation
                                            if (new Date(`${from}T00:00:00`).getTime() >= new Date(`${to}T23:59:59`).getTime()) {
                                                setErrorMessage(I18n.get('Can not select From greater than To!'))
                                                setIsLoading(false)
                                                setLoadingMessage('')
                                                return
                                            }

                                            //to future validation
                                            if (new Date(to).getTime() > new Date(moment().format('YYYY-MM-DDT23:59:59Z')).getTime()) {
                                                setErrorMessage(I18n.get('Can not select To greater than Today!'))
                                                setIsLoading(false)
                                                setLoadingMessage('')
                                                return
                                            }

                                            const data = await getFileCounts(new Date(`${from}T00:00:00`).getTime(), new Date(`${to}T23:59:59`).getTime())
                                            console.log('data', data)
                                            setFileCounts(data)
                                            setIsLoading(false)
                                            setLoadingMessage('')
                                            setErrorMessage('')


                                            // setIsLoading(true)
                                            // getFileCounts()
                                        }}>{I18n.get('Search')}</button>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="columns" style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">{I18n.get('From Date')}</label>
                                        <div className="control">
                                            <input className="input" type="date" name="from-date-picker" id="from-date-picker"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">{I18n.get('To Date')}</label>
                                        <div className="control">
                                            <input className="input" type="date" name="to-date-picker" id="to-date-picker"></input>
                                        </div>
                                    </div>

                                </div>
                                <div className="column">

                                </div>
                                <div className="column">


                                </div>
                            </div>

                            <div>
                                <div className="card">
                                    {
                                        errorMessage !== ''
                                            ? <header className="card-header" style={{ padding: '1%' }} id="messageDivParent">
                                                <div className="notification has-background-danger-light is-size-5 has-text-danger-dark is-fullwidth" >
                                                    <button className="delete" onClick={() => {
                                                        setErrorMessage('')
                                                    }}></button>
                                                    {errorMessage}
                                                </div>
                                            </header>
                                            : <div />
                                    }

                                    <div className="card-content">
                                        <div className="columns" style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                            <div className="column">
                                                <div className="field">
                                                    <label className="label">{I18n.get('Total Files Uploaded')}</label>
                                                    <div className="control">
                                                        <input className="input" type="number" name="to-date-picker" id="to-date-picker" value={fileCounts.allFiles || 0} disabled></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">{I18n.get('Files Successfully Converted')}</label>
                                                        <div className="control">
                                                            <input className="input" type="number" name="to-date-picker" id="to-date-picker" value={fileCounts.completed || 0} disabled></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">{I18n.get('Files Failed')}</label>
                                                        <div className="control">
                                                            <input className="input" type="number" name="to-date-picker" id="to-date-picker" value={fileCounts.failed || 0} disabled></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">

                                            <label className="label">{I18n.get('Average Success Ratio')}</label>
                                            <div className="control">
                                                <progress className="progress is-success is-fullwidth is-large" value={((fileCounts.completed * 100) / fileCounts.allFiles) || 0} max="100" ></progress>
                                            </div>
                                        </div>
                                        <div className="field">

                                            <label className="label">{I18n.get('Average Failure Ratio')}</label>
                                            <div className="control">
                                                <progress className="progress is-danger is-fullwidth is-large" value={((fileCounts.failed * 100) / fileCounts.allFiles) || 0} max="100" ></progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default FileCountsHandler;