import { createSlice } from '@reduxjs/toolkit';

export const UserInfoSlice = createSlice({
    name: 'UserInfo',
    initialState: {
        isAuthenticated: false,
        user: {},
        guest: '',
        username: '',
        language: 'en'
    },
    reducers: {
        isSetUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true
            //console.log'SetUser called, state updated to Auth!', state.isAuthenticated)
        },
        isSetGuest: (state, action) => {
            state.guest = action.payload
            state.isAuthenticated = false
            //console.log'SetGuest called, state updated to Guest!', state.isAuthenticated)
        },
        isSetAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        isSetUsername: (state, action) => {
            state.username = action.payload
        },
        isSetLanguage: (state, action) => {
            state.language = action.payload
        }
    }
})

export const { 
    isSetUser,
    isSetGuest,
    isSetAuthenticated,
    isSetUsername,
    isSetLanguage
} = UserInfoSlice.actions

export default UserInfoSlice.reducer