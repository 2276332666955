import { getBillFormats } from './queries/get-bill-formats';
import { executeStepFunctionMutation } from './mutations/execute-step-function';
import { API, graphqlOperation } from '@aws-amplify/api';
import { AuthAppSync, GuestAppSync } from '../aws-exports';
import { getCurrentSession } from '../auth/get-current-session';
import { checkExecutionStatusQuery } from './queries/check-execution-status';
import { getTaskHistoryQuery } from './queries/get-task-history';
import { sendHelpEmailMutation } from './mutations/send-help-email';
import { getFileCountsQuery } from './queries/get-file-counts';
import { createGuestUserMutation } from './mutations/create-guest-user';
import { getGuestUserQuery } from './queries/get-guest-user';
import { getProfileQuery } from './queries/get-profile';
API.configure(AuthAppSync)

export const getBillFormatsAPI = async () => {
    const { session } = await getCurrentSession()
    if (session) {
        //console.log'Appsync Auth mode enabled!')
        API.configure(AuthAppSync)
    } else {
        //console.log'AppSync Guest mode enabled!')
        API.configure(GuestAppSync)
    }

    return API.graphql({
        query: getBillFormats,
    })
        .then(res => {
            //console.log'Amplify query getBillFormats results', res)
            return res.data.getBillFormats
        })
        .catch(e => {
            //console.log'Amplify query getBillFormats error', e)
            return []
        })


}

export const executeStepFunction = async (request) => {
    return API.graphql(graphqlOperation(
        executeStepFunctionMutation,
        { info: request }
    ))
        .then(res => {
            //console.log'Amplify mutation executeStepFunction results', res)
            return res.data.executeStepFunction
        })
        .catch(e => {
            //console.log'Amplify mutation executeStepFunction error', e)
            return []
        })
}

export const createGuestUser = async (request) => {
    return API.graphql({
        query: createGuestUserMutation,
        authMode: 'AWS_IAM'
    })
        .then(res => {
            console.log('Amplify mutation createGuestUser results', res)
            return res.data.createGuestUser
        })
        .catch(e => {
            console.log('Amplify mutation createGuestUser error', e)
            return []
        })
}

export const sendHelpEmail = async (request) => {
    return API.graphql(graphqlOperation(
        sendHelpEmailMutation,
        { info: request }
    ))
        .then(res => {
            //console.log'Amplify mutation sendHelpEmail results', res)
            return res.data.sendHelpEmail
        })
        .catch(e => {
            //console.log'Amplify mutation sendHelpEmail error', e)
            return []
        })
}

export const checkExecutionStatus = async (executionArn) => {
    return API.graphql(graphqlOperation(
        checkExecutionStatusQuery,
        { executionARN: executionArn }
    ))
        .then(res => {
            //console.log'Amplify query checkExecutionStatusQuery results', res)
            return res.data.checkExecutionStatus
        })
        .catch(e => {
            //console.log'Amplify query checkExecutionStatusQuery error', e)
            return []
        })
}

export const getTaskHistory = async (from, to, limit, nextToken = false) => {
    //console.log'GetTaskHistory called with ', from , to, limit, nextToken)
    const param = {
        from,
        to,
        limit
    }

    if (nextToken) {
        param.nextToken = nextToken
    }

    return API.graphql(graphqlOperation(
        getTaskHistoryQuery,
        param))
        .then(res => {
            //console.log'Amplify query getTaskHistory results', res)
            return res.data.getTaskHistory
        })
        .catch(e => {
            //console.log'Amplify query getTaskHistory error', e)
            return []
        })
}

export const getFileCounts = async (from, to) => {
    //console.log'GetTaskHistory called with ', from , to, limit, nextToken)
    const param = {
        from,
        to
    }

    return API.graphql(graphqlOperation(
        getFileCountsQuery,
        param))
        .then(res => {
            //console.log'Amplify query getTaskHistory results', res)
            return res.data.getFileCounts
        })
        .catch(e => {
            //console.log'Amplify query getTaskHistory error', e)
            return {
                allFiles: 0,
                completed: 0,
                failed: 0
            }
        })
}

export const getGuestUser = async (id) => {
    //console.log'GetTaskHistory called with ', from , to, limit, nextToken)
    const param = {
        id
    }

    return API.graphql(graphqlOperation(
        getGuestUserQuery,
        param))
        .then(res => {
            //console.log'Amplify query getTaskHistory results', res)
            return res.data.getGuestUser
        })
        .catch(e => {
            //console.log'Amplify query getTaskHistory error', e)
            return {
                availableProcess: 0
            }
        })
}

export const getProfile = async () => {
    return API.graphql({
        query: getProfileQuery,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
        .then(res => {
            //console.log'Amplify query getTaskHistory results', res)
            return res.data.getProfile
        })
        .catch(e => {
            return {
                availableProcess: 0
            }
        })
}