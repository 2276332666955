import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import UserInfoSlice  from './user-info';
import UploadInfoSlice from './upload-info';
export default configureStore({
    reducer: {
        userInfo: UserInfoSlice,
        uploadInfo:  UploadInfoSlice,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
})


