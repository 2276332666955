import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar/navbar-protected';
import Footer from '../components/footer/footer';
import { getTaskHistory } from '../graphql/amplify-api';
import moment from 'moment';
import * as _ from "lodash";
import byteSize from 'byte-size';
import { Storage } from 'aws-amplify';
import { DownloadBucketConfig } from '../aws-exports';
import TaskHistoryTable from '../components/task-history/task-history-table';
import FileCountsHandler from '../components/file-usage-counts/file-usage-counts';
Storage.configure(DownloadBucketConfig)

const TaskHistory = (props) => {
    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div>
            <Navbar></Navbar>
            <FileCountsHandler />
            <TaskHistoryTable />
            <div style={{ marginTop: '6rem' }}><Footer></Footer></div>
        </div>
    );
}

export default TaskHistory;