import { Auth } from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';
import { v4 } from 'uuid';
import { createGuestUser } from '../graphql/amplify-api';

export const getCurrentSession = async () => {
    let currentSession, currentUser, attr
    try {
        currentSession = await Auth.currentSession()
        currentUser = await Auth.currentAuthenticatedUser()
        // attr = await Auth.userAttributes
        //console.log'Central session check Success! session found ->', currentSession)
    } catch (e) {
        //console.log'Central session check Failed!', 'error message from Amplify -> ', e)
    }

    return new Promise(async (resolve, reject) => {
        if (currentSession) {
            const groups = currentSession.accessToken.payload['cognito:groups'] || []
            console.log('Currentsession intenal', {
                session: currentSession,
                user: currentUser,
                isAdmin: groups.includes('Admin')
            })
            return resolve({
                session: currentSession,
                user: currentUser,
                isAdmin: groups.includes('Admin')
            })
        } else {


            //console.log'Checking guest Id!')
            const existingGuestCache = Cache.getItem('guest_info')
            if (existingGuestCache) {
                //console.log'Existing Guest cache found! Reusing same!', existingGuestCache)
                return resolve({
                    session: null,
                    user: {
                        guestId: existingGuestCache
                    }
                })
            } else {
                const guestData = await createGuestUser()
                console.log('Handshake API called', guestData)
                const expiry = new Date(3000, 12, 31);
                const uniqueId = guestData.id
                Cache.setItem('guest_info', uniqueId, { expires: expiry.getTime() })
                //console.log'Existing Guest cache found! Reusing same!', existingGuestCache)
                return resolve({
                    session: null,
                    user: {
                        guestId: uniqueId
                    }
                })
            }
        }
    })
}